import axios from 'axios';

const REACT_APP_API_URL: string | number = process.env.REACT_APP_API_URL || "https://evenements-nantes.com";

const getEvents = async () => {
  try {
    //console.error("REACT_APP_API_URL:", REACT_APP_API_URL)
    const response = await axios.get(`${REACT_APP_API_URL}/api/events/`);
    return response.data; // suppose que l'API renvoie les données directement
  } catch (error) {
    console.error("Error fetching events:", error);
    // Gérer l'erreur comme il se doit
  }
}

export default getEvents;
