import React, { useEffect, useRef  } from 'react';
import mapboxgl from 'mapbox-gl';
import { SchedulerEvent } from '../../../hooks/useEventData'; // Assurez-vous que le chemin est correct
import { useMap } from './mapContext';

interface MapProps {
  events: SchedulerEvent[]; // Utilisez le type pour vos props
}

const Map: React.FC<MapProps> = ({ events }) => {
  const mapContainer = useRef(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<{ [key: string]: mapboxgl.Marker }>({});
  const { highlightMarker, latitude, longitude, zoomLevel } = useMap();
 

  useEffect(() => {
    if (!mapContainer.current) return; // S'assure que le conteneur de la carte existe
  
    if (!map.current) {
      // Initialiser la carte si elle n'existe pas déjà
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-1.553621, 47.218371], // Coordonnées pour Nantes
        zoom: 12,
      });
    }

    // Fonction pour nettoyer les marqueurs
    const removeMarkers = () => {
      Object.values(markersRef.current).forEach(marker => marker.remove());
      markersRef.current = {};
    };

    // Fonction pour créer et ajouter des marqueurs
    const addMarkers = () => {
      events.forEach(event => {
        addMarker(event);
      });
    };

    const addMarker = (event: SchedulerEvent) => {
      const eventId = event.ExternalId; // Ou un autre champ unique de votre événement
    
      // Si location ou adresse existent // mettre location ou '' si ça existe pas puis adresse ou '' si ça existe pas
      let locationInfo = event.Location || (event.Address && event.Address!=='.') ? `${event.Location || ''}<br>${event.Address || '' }` : 'Localisation estimée';
      
      // Gérer l'information de temps
      let timeInfo = '';
      if (event.StartTime && event.EndTime) {
          const startDate = new Date(event.StartTime);
          const endDate = new Date(event.EndTime);
          const startTime = startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
          const endTime = endDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
          timeInfo = `De ${startTime} à ${endTime}`;
      }
      let DateInfo = event.EstimatedHours ? `${"Heures de début et de fin estimées"}<br>` : '';

      const popupContent = `
        <strong>${event.Subject}</strong><br>
        ${timeInfo}<br>
        ${DateInfo}
        ${locationInfo}<br>
        <a href="${event.UrlSite}" target="_blank">Plus d'informations</a>
      `;

      // Créez et ajoutez le nouveau marqueur
      const marker = new mapboxgl.Marker({ color: event.SousCategorieFrontendColor }) // Utilisez la couleur de la sous-catégorie
        .setLngLat([event.Longitude, event.Latitude])
        .setPopup(new mapboxgl.Popup().setHTML(popupContent))
        .addTo(map.current!);
    
      // Stockez la référence du nouveau marqueur
      markersRef.current[eventId] = marker;
    };

    // Nettoyez d'abord les anciens marqueurs, puis ajoutez les nouveaux
    removeMarkers();
    addMarkers();

    // Effet de nettoyage pour supprimer les marqueurs si le composant est démonté
    return () => {
      removeMarkers();
    };
  }, [events]);

  useEffect(() => {
    // Lorsque highlightMarker change, mettez en avant le marqueur correspondant
    if (highlightMarker && markersRef.current[highlightMarker]) {
      // Vous pouvez ajuster cette logique pour mettre en avant le marqueur, par exemple ouvrir sa popup
      const marker = markersRef.current[highlightMarker];
      marker.getPopup().addTo(map.current!);
    }
  }, [highlightMarker]);

  useEffect(() => {
    if (map.current && latitude !== null && longitude !== null && zoomLevel !== null) {

      map.current.flyTo({
        center: [longitude, latitude],
        zoom: zoomLevel,
      });
    }
  }, [latitude, longitude, zoomLevel]); // Réagissez aux changements de ces valeurs


  useEffect(() => {
    const mapInstance = map.current; // ou simplement utiliser map si vous n'utilisez pas useRef
    if (mapInstance) {
      const resizeObserver = new ResizeObserver(entries => {
        // Pour chaque élément redimensionné (dans ce cas, votre conteneur de carte), effectuez une action
        for (let entry of entries) {
          const { width, height } = entry.contentRect;
          //console.log(`Size changed to ${width}x${height}`);
          // Force la carte à ajuster à la nouvelle taille et recentrer le marqueur si nécessaire
          mapInstance.resize();
        }
      });
  
      // Commencer à observer le conteneur de la carte
      if (mapContainer.current) {
        resizeObserver.observe(mapContainer.current);
      }
  
      // Nettoyage de l'effet
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);
  

  return <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />;
};

export default Map;
