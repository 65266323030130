import { SchedulerResource } from '../components/FilterCalendarMap/Calendar/schedulerResources'; // Vérifiez le chemin d'accès
import { SchedulerEvent } from '../hooks/useEventData'; // Assurez-vous que le chemin d'importation est correct
import { ScheduleComponent, TimelineViews, ViewsDirective, ViewDirective, ResourcesDirective, ResourceDirective, NavigatingEventArgs, Inject, EventClickArgs, PopupOpenEventArgs} from '@syncfusion/ej2-react-schedule';
import { useMap } from '../components/FilterCalendarMap/Map/mapContext';

// Modification de la langue dans le scheduler
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import * as timeZoneNames from 'cldr-data/main/fr-CH/timeZoneNames.json';
import * as numbers from 'cldr-data/main/fr-CH/numbers.json';
import * as gregorian from 'cldr-data/main/fr-CH/ca-gregorian.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import FrenchLanguage from "../utils/fr.json";
import { useEffect, useRef } from 'react';

interface EventSettingsField {
    id: string; // Permettre n'importe quelle chaîne
    subject: { name: string };
    isAllDay: { name: string };
    startTime: { name: string };
    endTime: { name: string };
    externalId: { name: string };
    mediaUrl: { name: string };
  }
  
  interface EventSettings {
    dataSource: SchedulerEvent[]; // Assurez-vous que SchedulerEvent est correctement défini ailleurs dans votre code
    fields: EventSettingsField;
  }

interface RenderSchedulerProps {
  eventSettings: EventSettings;
  filteredMainCategories: SchedulerResource[];
  filteredSubCategories: SchedulerResource[];
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  setCurrentView: (view: string) => void;
}



export const RenderScheduler: React.FC<RenderSchedulerProps> = ({ eventSettings, filteredMainCategories, filteredSubCategories, selectedDate, setSelectedDate, setCurrentView }: RenderSchedulerProps) => {

  // Chargement de la langue
  loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
  L10n.load({'fr-CH': FrenchLanguage['fr'] });
  
  const { setHighlightMarker, centerMapOnMarker } = useMap()!;

  const onNavigating = (args: NavigatingEventArgs) => {
    if (args.action === 'date') {
      setSelectedDate(args.currentDate!); // Assurez-vous que currentDate n'est pas undefined
    } else if (args.action === 'view') {
      setCurrentView(args.currentView!); // Assurez-vous que currentView n'est pas undefined
    }
  };

  const onEventClicked = (args: EventClickArgs) => {
    let eventId: string | null = null;
    let eventLatitude: number | null = null;
    let eventLongitude: number | null = null;
  
    // Vérifiez si args.event est un tableau et prenez le premier élément si c'est le cas
    if (Array.isArray(args.event)) {
      eventId = args.event[0]?.ExternaId?.toString() ?? null;
      eventLatitude = args.event[0]?.Latitude?.toString() ?? null;
      eventLongitude = args.event[0]?.Longitude?.toString() ?? null;
    } else {
      // Sinon, accédez directement à l'Id
      eventId = args.event?.ExternalId?.toString() ?? null;
      eventLatitude = args.event?.Latitude ?? null;
      eventLongitude = args.event?.Longitude ?? null;
    }

    if (eventId) {
      setHighlightMarker(eventId);
    } else {
      console.error("Aucun ID d'événement trouvé.");
    }

    if (eventLatitude && eventLongitude) {
      const zoomLevel = 15;
      centerMapOnMarker(eventLatitude, eventLongitude, zoomLevel);
    } else {
      console.error("latitude et longitude à null.");
    }

    
  };

  const onPopupOpen = (args: PopupOpenEventArgs) => {
    const event = args.data as SchedulerEvent;
    if (args.type === 'QuickInfo') {
      const popupElement = args.element as HTMLElement; // Casting to HTMLElement
      if (popupElement) {
          popupElement.style.maxWidth = '500px'; // Ajustez ici selon vos besoins
      }
      const contentArea = args.element.querySelector('.e-popup-content');
      
      // Créez un nouvel élément 'br' pour le saut de ligne
      const breakElement = document.createElement('br');
      if(contentArea)
      {
        contentArea.appendChild(breakElement); // Ajoute le saut de ligne
      }

      const iconSpan = document.createElement('span');
      iconSpan.className = 'fas fa-at'; // Ajoutez la classe de Font Awesome
      
      const urlElement = document.createElement('a');
      urlElement.href = event.UrlSite;
      urlElement.target = '_blank';
      urlElement.style.marginLeft = '20px'; // Ajoute un peu d'espace après l'icône
      
      // Texte du lien
      urlElement.textContent = 'Plus d\'informations';
      
      if(contentArea)
      {
        // Ajoutez l'icône et le texte du lien à l'élément parent
        contentArea.appendChild(iconSpan);
        contentArea.appendChild(urlElement);
      }
    }
  };


  const scheduleRef = useRef<ScheduleComponent | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (scheduleRef.current) {
        const scheduleElement = scheduleRef.current.element;
        const scrollElement = scheduleElement.querySelector('.e-content-wrap') as HTMLElement;
        if (scrollElement) {
          scrollElement.scrollLeft = scrollElement.scrollWidth;
        }
      }
    }, 10); // Attendez 100 ms avant d'ajuster le scroll
  }, []);
  
  
  return (
      <div className="scheduler-container">
        <ScheduleComponent 
          firstDayOfWeek={1}
          rowAutoHeight={true}
          height='100vh'
          readonly={true}
          selectedDate={selectedDate}
          eventSettings={eventSettings}
          locale='fr-CH'
          timeFormat="HH:mm"
          dateFormat="dd/MM/yyyy"
          ref={scheduleRef}
          popupOpen={onPopupOpen}
          currentView='TimelineDay' // Définit la vue par défaut sur TimelineDay
          group={{ resources: ['Categories', 'SubCategories'] }}
          navigating={onNavigating} // Attachez ici le gestionnaire d'événements
          eventClick={onEventClicked}
          >

          <ResourcesDirective>
        {/* Ressource pour les catégories principales */}
          <ResourceDirective
            field='GroupId' // Ce champ doit correspondre à la propriété des événements qui contient l'ID de catégorie principale
            title='Category'
            name='Categories'
            allowMultiple={false}
            dataSource={filteredMainCategories}
            textField='text'
            idField='id' // Utilisez 'id' pour l'identification unique des catégories
            colorField='color'/>

          {/* Ressource pour les sous-catégories */}
          <ResourceDirective
          field='Id' // Ce champ doit correspondre à la propriété des événements pour l'ID de sous-catégorie
          title='SubCategory'
          name='SubCategories'
          allowMultiple={false}
          dataSource={filteredSubCategories}
          textField='text'
          idField='id' // Utilisez 'id' pour l'identification unique des sous-catégories
          groupIDField='groupId' // Ici, 'groupId' fait le lien entre la sous-catégorie et sa catégorie principale
          colorField='color'/>
        </ResourcesDirective>

        <ViewsDirective>
          <ViewDirective option='TimelineDay' />
          <ViewDirective option='TimelineWeek' />
        {/* Ajoutez d'autres vues Timeline comme nécessaire */}
        </ViewsDirective>   
      
        <Inject services={[TimelineViews]} />
        </ScheduleComponent>

      </div>

    );


  }

