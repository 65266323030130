import { useState, useEffect } from 'react';
import getEvents from '../services/eventDataService';
import { SchedulerResource } from '../components/FilterCalendarMap/Calendar/schedulerResources'; // Ajustez le chemin selon l'emplacement réel


export interface SchedulerEvent {
  ExternalId: string
  Id: number;
  GroupId: number;
  Subject: string;
  StartTime: Date;
  EndTime: Date;
  EstimatedHours: boolean;
  IsAllDay: boolean; // Selon que vous utilisiez ou non cette fonctionnalité
  Description: string;
  Type: string;
  Address?: string;
  Location?: string;
  IsReadOnly: boolean;
  CategorieFrontend?: string;
  SousCategorieFrontend?: string;
  Latitude: number;
  Longitude:number;
  SousCategorieFrontendColor:string;
  UrlSite: string;
  MediaUrl: string;
  
  //Ajouter les paramètres au fur et à mesure
}

const useEventData = (mainCategories: SchedulerResource[], subCategories: SchedulerResource[]) => {
  const [events, setEvents] = useState<SchedulerEvent[]>([]); // Utilisez le type générique pour définir que c'est un tableau d'Event
  const [loading, setLoading] = useState(true); // Initialisez loading

  useEffect(() => {
    const fetchEvents = async () => 
    {
      setLoading(true);
      try {
        const eventData = await getEvents();
        if (Array.isArray(eventData)) 
        {
          const mappedEvents: SchedulerEvent[] = eventData.map(event =>
          {
            // Trouver l'ID de la catégorie principale basée sur event.categorie_frontend
            const categorie = mainCategories.find(cat => cat.text === event.categorie_frontend);
            const categoryId = categorie ? categorie.id : 4; // catégorie "Autre"

            // Trouver l'ID de la sous-catégorie basée sur event.sous_categorie_frontend
            const sousCategorie = subCategories.find(sub => sub.text === event.sous_categorie_frontend);
            const sousCategorieId = sousCategorie ? sousCategorie.id : 16; // sous-catégorie "Autre"

            // Trouver la couleur de la sous-catégorie ou une couleur par défaut si non trouvée
            const sousCategorieColor = sousCategorie ? sousCategorie.color : '#000000'; // Noir par défaut

            return {
              ExternalId: event.id_,
              Id: sousCategorieId, // L'ID de la sous-catégorie
              GroupId: categoryId, // L'ID de la catégorie principale pour le regroupement
              Subject: event.nom,
              StartTime: event.start_time,
              EndTime: event.end_time,
              EstimatedHours: event.estimated_hours,
              IsAllDay: event.is_all_day,
              Description: event.description,
              Type: event.type,
              Adress: event.adresse,
              Location: event.lieu,
              IsReadOnly: true,
              Address: event.adresse,
              Latitude: event.latitude,
              Longitude:event.longitude,
              SousCategorieFrontendColor: sousCategorieColor,
              UrlSite: event.url_site,
              MediaUrl: event.media_url

            // Incluez ici d'autres propriétés si nécessaire
            };
          });
          setEvents(mappedEvents);

        } else 
        {
          console.error('Expected an array of events, but got:', eventData);
        }
      } catch (error) {
        console.error('Failed to fetch events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [mainCategories, subCategories]);

  return { events, loading };
}

export default useEventData;

