// src/hooks/useDateSelection.ts

import { useState, useEffect } from 'react';
import { startOfWeek, endOfWeek, isSameDay } from 'date-fns';
import { SchedulerEvent } from './useEventData' // Adaptez le chemin d'accès en fonction de l'emplacement de votre type d'événement

interface UseDateSelectionProps {
  events: SchedulerEvent[];
  selectedDate: Date;
  currentView: string; // 'Day' ou 'Week'
}

interface UseDateSelectionReturn {
  filteredEvents: SchedulerEvent[];
}

export const useDateSelection = ({
  events,
  selectedDate,
  currentView,
}: UseDateSelectionProps): UseDateSelectionReturn => {
  const [filteredEvents, setFilteredEvents] = useState<SchedulerEvent[]>([]);

  useEffect(() => {

    const filterEvents = () => {
      if (currentView === 'TimelineWeek') {
        const weekStart = startOfWeek(selectedDate, { weekStartsOn: 1 });
        const weekEnd = endOfWeek(selectedDate, { weekStartsOn: 1 });

        return events.filter(event => {
          const eventDate = new Date(event.StartTime); // Assurez-vous que vos événements ont une propriété startTime
          const isInWeek = eventDate >= weekStart && eventDate <= weekEnd;

          return isInWeek;
        });
      } else { // Vue 'Day'
        return events.filter(event => {
            const isSame = isSameDay(new Date(event.StartTime), selectedDate);
            return isSame;
          });      }
    };
    
    setFilteredEvents(filterEvents());

  }, [events, selectedDate, currentView]);

  return { filteredEvents };
};
