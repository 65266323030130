import React, { useState, useMemo } from 'react';
import { useCategorySelection } from '../../hooks/useCategorySelection';
import  useEventData, { SchedulerEvent } from '../../hooks/useEventData';
import { useDateSelection } from '../..//hooks/useDateSelection';
import { useSchedulerResources } from '../FilterCalendarMap/Calendar/schedulerResources';
import {RenderFilters} from '../../utils/renderFilters';
import {RenderScheduler} from '../../utils/renderScheduler';
import Map from '../FilterCalendarMap/Map/mapBoxIntegration';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

const FilterCalendarMap = () => {
  const { mainCategories, subCategories } = useSchedulerResources();
  const { events, loading } = useEventData(mainCategories, subCategories);
  const { selectedIds, toggleCategorySelection, categoriesWithSelection } = useCategorySelection({ mainCategories, subCategories });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentView, setCurrentView] = useState('Day');

  const getFilteredEventsByCategoryAndSubCategory = (events: SchedulerEvent[], selectedIds: Set<number>) => {
    return events.filter(event => selectedIds.has(event.GroupId) && selectedIds.has(event.Id));
  };
  
  const filteredEventsByCategoryAndSubCategory = useMemo(() => {
    return getFilteredEventsByCategoryAndSubCategory(events, selectedIds);
  }, [events, selectedIds]);

  const { filteredEvents } = useDateSelection({ events: filteredEventsByCategoryAndSubCategory, selectedDate, currentView });

  const fieldsData = { id: 'Id', subject: { name: 'Subject' }, isAllDay: { name: 'IsAllDay' },
  startTime: { name: 'StartTime' }, endTime: { name: 'EndTime' },
  externalId: { name: 'ExternalId'},
  urlSite: { name: 'MediaUrl'},
  mediaUrl: { name: 'MediaUrl'},
  type: { name: 'Type'}}

  // Fonction pour formater les heures
  function formatEventTimes(events: SchedulerEvent[]) {
    return events.map(event => ({
      ...event,
      StartTimeFormatted: new Date(event.StartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      EndTimeFormatted: new Date(event.EndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    }));
  }
  
  const formattedEvents = useMemo(() => formatEventTimes(events), [events]);
  // Passez vos événements originaux ici

  const eventSettings  = { dataSource: formattedEvents , fields: fieldsData, enableTooltip: true,
                            tooltipTemplate:  `<div class='tooltip-content'>
                            \${if (MediaUrl)}<img src='\${MediaUrl}' alt='Event Image' class='tooltip-image' />\${/if}
                            <div class='tooltip-text'>
                              <div class='subject'>\${Subject}</div>
                              <div class='time'>De \${StartTimeFormatted} à \${EndTimeFormatted}</div>
                              <div class='time'>\${Type} </div>
                              </div>
                          </div>`  }

                         

  const filteredMainCategories = useMemo(() => 
    mainCategories.filter(cat => selectedIds.has(cat.id)),
    [mainCategories, selectedIds]
  );

  const filteredSubCategories = useMemo(() => 
    subCategories.filter(sub => selectedIds.has(sub.id)),
    [subCategories, selectedIds]
  );

  if (loading || !mainCategories || !subCategories) {
    return <div></div>;
  }

  return (
    <PanelGroup direction="horizontal">
      <Panel defaultSize={15} minSize={10} maxSize={20}>
        <RenderFilters categoriesWithSelection={categoriesWithSelection} toggleCategorySelection={toggleCategorySelection} />
      </Panel>
      <PanelResizeHandle />
      <Panel defaultSize={55} minSize={40} maxSize={70}>
        <RenderScheduler
          eventSettings={ eventSettings }
          filteredMainCategories={filteredMainCategories}
          filteredSubCategories={filteredSubCategories}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setCurrentView={setCurrentView}
        />
      </Panel>
      <PanelResizeHandle />
      <Panel defaultSize={30} minSize={20} maxSize={40}>
        <Map events={filteredEvents} />
      </Panel>
    </PanelGroup>
  );
}

export default FilterCalendarMap;
