import { useState, useEffect } from 'react';
import { SchedulerResource } from '../components/FilterCalendarMap/Calendar/schedulerResources'; // Vérifiez le chemin

interface UseCategorySelectionParams {
  mainCategories: SchedulerResource[];
  subCategories: SchedulerResource[];
}

interface UseCategorySelectionReturn {
  selectedIds: Set<number>;
  toggleCategorySelection: (id: number) => void;
  categoriesWithSelection: SchedulerResource[];
}

export const useCategorySelection = ({
  mainCategories,
  subCategories,
}: UseCategorySelectionParams): UseCategorySelectionReturn => {
  const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set());
  
  useEffect(() => {
    // Initialisation des selectedIds avec tous les IDs
    const allIds = new Set([...mainCategories, ...subCategories].map(cat => cat.id));
    setSelectedIds(allIds);
  }, [mainCategories, subCategories]);

  const toggleCategorySelection = (id: number) => {
    setSelectedIds(prevSelectedIds => {
      const newSelection = new Set(prevSelectedIds);
      if (newSelection.has(id)) {
        newSelection.delete(id);
      } else {
        newSelection.add(id);
      }
      return newSelection;
    });
  };

  // Enrichir les catégories avec leur état `selected` pour l'affichage
  const categoriesWithSelection = [...mainCategories, ...subCategories].map(cat => ({
    ...cat,
    selected: selectedIds.has(cat.id),
  }));

  return { selectedIds, toggleCategorySelection, categoriesWithSelection };
};
