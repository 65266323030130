import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/footerBanner.css';

const FooterBanner: React.FC = () => {
  return (
    <div className="footer-banner">
      <ul>
        <li><Link to="/">Calendrier d'événements</Link></li>
        <li><Link to="/mentions-legales">Mentions légales</Link></li>
      </ul>
    </div>
  );
}

export default FooterBanner;
