import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { registerLicense } from '@syncfusion/ej2-base';

import './styles/index.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from 'mapbox-gl';
import { MapProvider } from './components/FilterCalendarMap/Map/mapContext'; 
import FilterCalendarMap  from './components/FilterCalendarMap/FilterCalendarMap';
import FooterBanner from './components/FooterBanner/FooterBanner';

import MentionsLegales from './components/FooterBanner/pages/mentions_legales';

if (typeof process.env.REACT_APP_SYNCFUSION_LICENSE === "string") {
  registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);
} else {
  console.error("Syncfusion license key is undefined");
}

// Registering Syncfusion license key
if (typeof process.env.REACT_APP_MAPBOX_ACCESS_TOKEN === "string") {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
} else {
  console.error("Syncfusion license key is undefined");
}


/** 
const App = () => {

}
export default App;
**/
// Tentative de récupération de l'élément racine
const container = document.getElementById('root');

// Vérifiez que container n'est pas null avant de continuer
if (container !== null) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Router>
      <div className="main-content">
        <MapProvider>
          <Routes>
            <Route path="/" element={<FilterCalendarMap />} />
            <Route path="/mentions-legales" element={<MentionsLegales />} />
            {/* Autres routes ici */}
          </Routes>
          <FooterBanner />
        </MapProvider>
        </div>
      </Router>
    </React.StrictMode>
  );
} else {
  console.error('Failed to find the root element');
}

