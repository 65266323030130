import React, { createContext, useContext } from 'react';


// Définition de l'interface pour une ressource
export interface SchedulerResource {
  text: string;
  id: number;
  groupId?: number;
  color: string;
  selected:boolean;
}

// Ressources principales
const mainCategories: SchedulerResource[] = [
  { text: 'Événements culturels', id: 1, color: '#8a2be2', selected: true }, // Violet
  { text: 'Événements sportifs', id: 2, color: '#fdd017', selected: true }, // Jaune Doré
  { text: 'Dialogue Citoyen / Emploi', id: 3, color: '#1e90ff', selected: true }, // Bleu Dodger
  { text: 'Autres événements', id: 4, color: '#ff4500', selected: true } // Orange Rouge
];

// Sous-catégories pour Culture et Sports
const subCategories: SchedulerResource[] = [
  { text: 'Spectacles Vivants', id: 6, groupId: 1, color: '#da70d6', selected: true }, // Orchidée
  { text: 'Musique et Son', id: 7, groupId: 1, color: '#3cb371', selected: true }, // Vert Mer Moyen
  { text: 'Arts Visuels et Expositions', id: 8, groupId: 1, color: '#7b68ee', selected: true }, // Bleu Moyen Ardoise
  { text: 'Éducation et Créativité', id: 9, groupId: 1, color: '#ff69b4', selected: true }, // Rose Vif
  { text: 'Marchés et Fêtes traditionnelles', id: 10, groupId: 1, color: '#ffa07a', selected: true }, // Lumière Saumon
  { text: 'Autres événements culturels', id: 11, groupId: 1, color: '#00bfff', selected: true }, // Bleu Ciel profond
  { text: 'Événements sportifs', id: 12, groupId: 2, color: '#fdd017', selected: true }, // Jaune Doré
  { text: 'Dialogue Citoyen', id: 13, groupId: 3, color: '#4682b4', selected: true }, // Bleu Acier
  { text: 'Événements Emploi', id: 14, groupId: 3, color: '#32cd32', selected: true }, // Vert Lime
  { text: 'Autres événements', id: 15, groupId: 4, color: '#808080', selected: true }, // Sable Brun
];


// Création du contexte
const SchedulerContext = createContext<{
  mainCategories: SchedulerResource[];
  subCategories: SchedulerResource[]; }>
  ({ mainCategories, subCategories });


// Type pour les props de SchedulerProvider, explicitant `children`
interface SchedulerProviderProps {
  children: React.ReactNode;
}

// Export du provider pour englober l'application ou une partie de l'application
  export const SchedulerProvider: React.FC<SchedulerProviderProps> = ({ children }) => (
    <SchedulerContext.Provider value={{ mainCategories, subCategories }}>
      {children}
    </SchedulerContext.Provider>
  );
  
// Hook pour utiliser le contexte
export const useSchedulerResources = () => useContext(SchedulerContext);
