import { SchedulerResource } from '../components/FilterCalendarMap/Calendar/schedulerResources';
import CategoryFilters from '../components/FilterCalendarMap/Filters/categoriesFilters';

interface RenderFiltersProps {
  categoriesWithSelection: SchedulerResource[];
  toggleCategorySelection: (id: number) => void;
}

export const RenderFilters = ({ categoriesWithSelection, toggleCategorySelection }: RenderFiltersProps) => {
  return (
    <div className="filters-container">
      <CategoryFilters categories={categoriesWithSelection} onCategoryChange={toggleCategorySelection} />
    </div>
  );
}
