import React, { createContext, useContext, useState, ReactNode, FunctionComponent } from 'react';

interface MapContextType {
  highlightMarker: string | null;
  setHighlightMarker: (id: string | null) => void;
  centerMapOnMarker: (latitude: number | null, longitude: number | null, zoomLevel: number | null) => void;
  latitude: number | null;
  longitude: number | null;
  zoomLevel: number | null;
}

const defaultContext: MapContextType = {
  highlightMarker: null,
  setHighlightMarker: () => {},
  centerMapOnMarker: () => {},
  latitude: null,
  longitude: null,
  zoomLevel: null,
};

const MapContext = createContext<MapContextType>(defaultContext);

export const useMap = () => useContext(MapContext);

interface MapProviderProps {
  children: ReactNode;
}

export const MapProvider: FunctionComponent<MapProviderProps> = ({ children }) => {
  const [highlightMarker, setHighlightMarker] = useState<string | null>(null);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number | null>(null);

  const centerMapOnMarker = (lat: number | null, lng: number | null, zoom: number | null) => {
    setLatitude(lat);
    setLongitude(lng);
    setZoomLevel(zoom);
  };

  return (
    <MapContext.Provider value={{ highlightMarker, setHighlightMarker, centerMapOnMarker, latitude, longitude, zoomLevel }}>
      {children}
    </MapContext.Provider>
  );
};
