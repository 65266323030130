import React from 'react';

const LegalMentions: React.FC = () => {
  return (
    <div style={{ margin: '0 auto', maxWidth: '1000px', padding: '20px' }}>
      <h1>Mentions Légales</h1>

      <h2>Charte de Responsabilité</h2>
      <p>
        Bienvenue sur notre site web, une plateforme dédiée à la centralisation des événements à Nantes via diverses sources de données.
        Notre objectif est de vous fournir une vue exhaustive et actualisée des activités disponibles,
        pour vous inspirer et vous aider à planifier vos sorties. En naviguant sur notre site, vous acceptez les termes de notre charte de
        responsabilité.
      </p>

      <p>
        <strong>Précision des Informations :</strong> Bien que nous nous efforcions de présenter des informations exactes et à jour,
        la véracité des données affichées sur notre site dépend des sources externes à partir desquelles ces informations sont obtenues.
        Nous ne pouvons donc pas garantir l'exactitude, l'exhaustivité ou l'actualité des informations relatives aux événements. 
        Les informations peuvent changer sans préavis, et nous ne sommes pas responsables des erreurs ou des omissions résultant de ces sources externes.
      </p>

      <p>
        <strong>Responsabilité des Utilisateurs :</strong> En utilisant notre site, vous acceptez de le faire de manière responsable
        et de ne pas engager d'activités qui pourraient nuire à notre site ou aux autres utilisateurs. Vous êtes responsable de toute
        action effectuée et vous acceptez de respecter toutes les lois et réglementations applicables.
      </p>
      <p>
        <strong>Liens Externes :</strong> Notre site peut contenir des liens vers des sites externes qui ne sont pas sous notre contrôle.
        Nous ne sommes pas responsables du contenu ou de la disponibilité de ces sites externes. L'inclusion de tout lien n'implique
        pas une approbation de notre part.
      </p>
      <p>
        <strong>Limitation de Responsabilité :</strong> Nous ne serons en aucun cas responsables des dommages directs, indirects,
        accidentels, consécutifs ou spéciaux résultant de votre utilisation de notre site ou des informations qu'il contient.
        Cette limitation de responsabilité s'applique dans toute la mesure permise par la loi.
      </p>
      <p>
        <strong>Modifications de la Charte :</strong> Nous nous réservons le droit de modifier cette charte de responsabilité à tout
        moment. Les modifications seront effectives dès leur publication sur cette page. Nous vous encourageons à consulter régulièrement
        cette page pour rester informé des éventuelles mises à jour.
      </p>
      <p>
        <strong>Contact :</strong> Si vous avez des questions concernant cette charte de responsabilité ou si vous souhaitez contribuer à l'amélioration du site,
        notamment par l'ajout de nouvelles sources de données ou par des suggestions générales, n'hésitez pas à nous contacter à evenements-nantes@outlook.com.
        Nous sommes ouverts à vos retours et à vos idées pour enrichir notre plateforme.
      </p>

      <h2>Source des Données</h2>
      <p>
        Les informations relatives aux événements présentées sur ce site sont issues des sources suivantes :
      </p>
      <p>
        <strong>Ville de Nantes et Nantes Métropole :</strong> Ces données sont mises à disposition sous les termes de la Licence
        Ouverte/Open Licence. La base de données est accessible directement via{' '}  
        <a href="https://nantesmetropole.opendatasoft.com/explore/dataset/244400404_agenda-evenements-nantes-nantes-metropole/table/?disjunctive.emetteur&disjunctive.rubrique&disjunctive.lieu&disjunctive.ville&disjunctive.lieu_quartier" target="_blank"> ce lien</a>.
        Mise à jour des données : Les informations sur ce site sont mises à jour quotidiennement pour refléter les
        dernières mises à jour disponibles de la base de données.
      </p>
      <p>
        <strong>Ticketmaster :</strong> Ce site utilise la Discovery API de Ticketmaster pour afficher des informations sur les
        événements. Les données utilisées sont fournies par Ticketmaster et sont régies par leurs conditions d'utilisation. Ce site n'est pas affilié à Ticketmaster et ne représente pas Ticketmaster.
      </p>

      <h2>Librairies Logicielles Utilisées</h2>
      <p>
        <strong>Syncfusion React Scheduler :</strong> Ce site web utilise le composant React Scheduler de Syncfusion, qui est sous
        licence Syncfusion Community License. Syncfusion fournit une large gamme de composants logiciels et de contrôles pour la création
        d'applications modernes. Notre utilisation de tels composants est régie par les termes et conditions de la
        Syncfusion Community License. Pour plus de détails sur les termes de la licence, veuillez visiter la{' '}
        <a href="https://www.syncfusion.com/products/communitylicense" target="_blank">page de licence Syncfusion</a>.
      </p>
      <p>
        <strong>Mapbox GL JS :</strong> Ce site utilise Mapbox GL JS pour les fonctionnalités de cartographie. Conformément aux termes du
        service Mapbox, nous incluons l'attribution requise comprenant le logo de Mapbox et l'attribution textuelle sur nos cartes. Mapbox
        est une plateforme de localisation offrant des outils puissants pour l'intégration de cartes et de données géographiques. Pour plus
        d'informations sur les termes d'utilisation, veuillez consulter la{' '}
        <a href="https://www.mapbox.com/legal/tos/" target="_blank">page de licence de Mapbox</a>.
      </p>

      <h2>Hébergeur</h2>
      <p>
        <strong>Hostinger :</strong> Notre site web est hébergé par Hostinger, un prestataire de services d'hébergement web. Hostinger propose une
        gamme de services d'hébergement web et de domaines à des clients du monde entier. Pour plus d'informations sur Hostinger et ses services,
        veuillez consulter leur site web officiel.
      </p>
    </div>
    
  );
}

export default LegalMentions;
