import React from 'react';
import { useSchedulerResources } from '../Calendar/schedulerResources'; // Assurez-vous que le chemin est correct

interface Category {
  id: number;
  text: string;
  selected: boolean;
  groupId?: number; // Propriété optionnelle pour identifier les sous-catégories
}

interface CategoryFiltersProps {
  categories: Category[];
  onCategoryChange: (categoryId: number) => void;
}

const CategoryFilters: React.FC<CategoryFiltersProps> = ({ categories, onCategoryChange }) => {
  const { subCategories } = useSchedulerResources();

  // Création d'un objet pour un accès rapide aux couleurs par ID
  const colorMap = subCategories.reduce((acc: { [x: string]: any; }, cur: { id: string | number; color: any; }) => {
    acc[cur.id] = cur.color; // Associe l'ID de la sous-catégorie à sa couleur
    return acc;
  }, {} as { [id: number]: string });

  // Séparer les catégories principales des sous-catégories
  const mainCategories = categories.filter(c => c.groupId === undefined);
  const subCategoriesList = categories.filter(c => c.groupId !== undefined);

  return (
    <div>
      <h2 style={{ fontWeight: 'bold' }}>Catégories d'activités</h2>
      {mainCategories.map(mainCategory => (
        <div key={mainCategory.id} className="category-filter-group">
          <div className="main-category">
            {mainCategory.text}
          </div>
          {subCategoriesList
            .filter(subCategory => subCategory.groupId === mainCategory.id)
            .map(subCategory => (
              <div key={subCategory.id} className="subcategory">
                <label style={{ color: colorMap[subCategory.id] || 'initial' }}>
                  <input
                    type="checkbox"
                    checked={subCategory.selected}
                    onChange={() => onCategoryChange(subCategory.id)}
                  />
                  {subCategory.text}
                </label>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default CategoryFilters;
